import React, { useEffect, useState } from 'react';
import './App.css';
import { motion, useAnimation } from 'framer-motion';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ImageData = {
    filename: string;
    location: string;
    region: string;
    date: string;
    time: string;
};

const fetchImageData = async (): Promise<ImageData[]> => {
    const response = await fetch('/data.json');
    const data = await response.json();
    return data;
};

const shuffleArray = (array: any[]) => {
    return array.sort(() => Math.random() - 0.5);
};

function ImageComponent({ image }: { image: ImageData }) {
    const controls = useAnimation();
    const ref = React.useRef<HTMLDivElement>(null);
    const [locked, setLocked] = useState(true);

    useEffect(() => {
        const currentRef = ref.current;

        const lockTimeout = setTimeout(() => setLocked(false), 1000);

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !locked) {
                        controls.start({ opacity: 1, y: 0 });
                    }
                });
            },
            { threshold: 0.2 }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            clearTimeout(lockTimeout);
        };
    }, [controls, locked]);

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
            transition={{ duration: 1.2, ease: 'easeOut' }}
            className="relative group"
        >
            <img
                src={image.filename}
                alt={image.date}
                className="w-full rounded-md object-cover select-none pointer-events-none"
                draggable="false"
            />
            <div
                className="absolute bottom-0 left-0 w-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 h-[30%] sm:h-[25%] md:h-[20%]"
            >
                <div className="w-full h-full bg-gradient-to-t from-black to-transparent flex items-end">
                    <div className="p-2 text-white text-sm select-none pointer-events-none flex justify-between w-full">
                        <div>
                            <p>{image.location || "Location not available"}</p>
                            <p className="font-thin">{image.region || "Region not available"}</p>
                        </div>

                        <div className="text-right">
                            <p>{image.date || "Date not available"}</p>
                            <p className="font-thin">{image.time || ""}</p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

function App() {
    const [shuffledImages, setShuffledImages] = useState<ImageData[]>([]);

    useEffect(() => {
        const loadData = async () => {
            const data = await fetchImageData();
            setShuffledImages(shuffleArray([...data]));
        };
        loadData();
    }, []);

    const leftColumnImages = shuffledImages.filter((_, index) => index % 2 === 0);
    const rightColumnImages = shuffledImages.filter((_, index) => index % 2 !== 0);
    const linkControls = useAnimation();
    const linkRef = React.useRef<HTMLDivElement>(null);
    const [linkLocked, setLinkLocked] = useState(true);

    useEffect(() => {
        const currentLinkRef = linkRef.current;
        const linkLockTimeout = setTimeout(() => setLinkLocked(false), 2000);
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !linkLocked) {
                        linkControls.start({ opacity: 1, y: 0 });
                    }
                });
            },
            { threshold: 0.2 }
        );

        if (currentLinkRef) {
            observer.observe(currentLinkRef);
        }

        return () => {
            if (currentLinkRef) {
                observer.unobserve(currentLinkRef);
            }
            clearTimeout(linkLockTimeout);
        };
    }, [linkControls, linkLocked]);


    return (
        <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-12 py-8 sm:py-10 md:py-12 lg:py-16 bg-black min-h-screen">
            <div className="flex flex-col md:flex-row gap-x-4 sm:gap-x-6 md:gap-x-8 lg:gap-x-12 gap-y-4 sm:gap-y-6 md:gap-y-8 lg:gap-y-12">
                <div className="w-full md:w-1/2 space-y-4 sm:space-y-6 md:space-y-8 lg:space-y-12">
                    <a href="https://kgniewek.pics" className="flex items-center space-x-4 mb-6 sm:mb-8 md:mb-10 lg:mb-12">
                        <img
                            src="/kgpics.webp"
                            alt="Krzysztof Gniewek Pics Logo"
                            className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20"
                        />
                        <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extralight">
                            Krzysztof Gniewek Pics
                        </h1>
                    </a>
                    {leftColumnImages.map((image, index) => (
                        <ImageComponent key={index} image={image} />
                    ))}
                </div>

                <div className="w-full md:w-1/2 space-y-4 sm:space-y-6 md:space-y-8 lg:space-y-12">
                    {rightColumnImages.map((image, index) => (
                        <ImageComponent key={index} image={image} />
                    ))}

                    <motion.div
                        ref={linkRef}
                        initial={{ opacity: 0, y: 50 }}
                        animate={linkControls}
                        transition={{ duration: 1.2, ease: 'easeOut' }}
                        className="flex justify-center items-center mt-10 sm:mt-8"
                    >
                        <a
                            href="https://kgniewek.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white text-2xl flex items-center space-x-2 group"
                        >
                            <img
                                src="/portfolio.webp"
                                alt="Portfolio Logo"
                                className=" w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 transform transition-transform duration-700 group-hover:rotate-[360deg]"
                            />
                            <span>kgniewek.com</span>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="text-lg mt-[-15px] transform -rotate-45 text-white transition-transform duration-500 ease-out group-hover:translate-x-2 group-hover:-translate-y-1.5"
                            />
                        </a>




                    </motion.div>

                </div>
            </div>
        </div>
    );
}

export default App;
